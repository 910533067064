<!-- 管理员管理 -->
<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
</style>
<script>
import Layout from "@/views/layouts/main";
import YzSearch from "@/components/form/yzSearch.vue";
import UserHeader from "@/views/pages/admin/user/user-header";
import {
  getUserAccountBackDetails,
  getUserAccountBackPage,
  deleteUserAccountBack,
  handleUserAccountBack
} from "@/api/admin/user/userAccountBack";
// import Multiselect from "vue-multiselect";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    UserHeader,
    YzSearch,
    // Multiselect
  },
  data() {
    return {
      items1: [
        {
          text: "用户首页",
          href: "/admin",
        },
        {
          text: "用户账号赎回",
          active: true,
        },
      ],
      items: [
      ],
      checked: true,
      defaultvalue: 3,
      currentpage: 3,
      pageData: {
        nowPage: 1,
        pageSize: 20,
        total: 0,
      },
      form: {},
      userShow: false,
      dict: {},
      search: [

      ],
      showPromission: false,
      bounds: {"身份证信息": [
          {label: "姓名", bound: "zjxm", width:"50"},
          {label: "性别", bound: "zjxb", width:"50"},
          {label: "民族", bound: "zjmz", width:"50"},
          {label: "出生日期", bound: "zjcsrq", width:"50"},
          {label: "住址", bound: "zjzz", width:"50"},
          {label: "号码", bound: "zjhm", width:"500"},
          {label: "签发机关", bound: "zjqfjg", width:"50"},
          {label: "有效日期", bound: ["zjyxksrq", "zjyxjsrq"], type: "join", join: '-', width:"50"},
        ]},
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.pageNum = val;
      this.getList();
    },
    getList() {
      getUserAccountBackPage(this.pageData).then(res=>{
        if(res.status){
          this.items = res.data
          this.pageData.total = res.total
          this.pageData.pageNum = res.pageNum;
          this.pageData.pageSize= res.pageSize;
        }
      })
    },
    searchClick() { //搜索时先将分页的页码归1
      this.pageData.pageNum = 1; //当前页				
      this.getList() //渲染数据的函数
    },
    startHandle(sid){
      getUserAccountBackDetails(sid).then(res=>{
        if (res.status){
          this.form = res.data
          this.userShow = true
        }
      })
    },
    handle(status){
      this.form.zjshzt = status
      handleUserAccountBack(this.form.sid, this.form).then(res=>{
          if (res.status){
            this.$notify({
              title: "成功",
              message: "审核成功",
              type: "success",
            });
            this.getList()
            this.userShow = false
          }
      })
    },
    deleteUser(sid){
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteUserAccountBack(sid).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.userShow = false
            this.getList();
          }
        });
      });
    },
  },
  mounted() {
    this.search = [
      {
        title: "姓名/移动电话/身份证件号",
        bound: "keyword",
        type: "input",
      },
    ]
    this.getList();
  },
};
</script>
<style type="text/css" scoped>
.input-width {
  width: 200px;
}
</style>

<template>
  <Layout>
    <UserHeader :items="items1" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <yz-search
                    :data="search"
                    v-model="pageData"
                    @seachList="searchClick"
                    w
                ></yz-search>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab">
                  <i class="iconfont icon-antOutline-file-excel mr-2"></i>excel
                </div>
                <div class="border-blue export-tab">
                  <i class="iconfont icon-data mr-2"></i>dbf
                </div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover">
                <thead class="thead-light">
                <tr>
                  <th style="width: 4%">序号</th>
                  <th style="width: 10%">姓名</th>
                  <th style="width: 15%">身份证号</th>
                  <th style="width: 10%;">移动电话</th>
                  <th>审核说明</th>
                  <th style="width: 10%">申请时间</th>
                  <th style="width: 8%;">修改人</th>
                  <th style="width: 8%;">修改时间</th>
                  <th style="width: 10%">审核</th>
                  <th style="width: 3%">操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in items" :key="i">
                  <td scope="row">{{  (pageData.pageNum - 1) * pageData.pageSize +  i + 1 }}</td>
                  <td>{{ item.xm }}</td>
                  <td>{{ item.sfzjh }}</td>
                  <td>{{ item.yddh }}</td>

                  <td>{{ item.sfztxhysm?('（人像面）:'+item.sfztxhysm):'' }}{{item.sfztxhysmfm?('（国徽面）:'+item.sfztxhysmfm):''}}</td>
                  <td>{{ timeSlice(item.createTime) }}</td>

                  <td>{{item.modifyUser}}</td>
                  <td>{{timeSlice(item.modifyTime)}}</td>
                  <td title="点击审核">
                    <a href="javascript:;" @click="startHandle(item.sid)">
                      <div class="text-success" v-if="item.zjshzt == 1">
                        <i class="iconfont icon-a-ze-checkedCopy2 mr-2"></i
                        >通过
                      </div>
                      <div class="text-danger" v-else-if="item.zjshzt == 2">
                        <i class="iconfont icon-ze-clear text-danger mr-2"></i
                        >未通过
                      </div>
                      <div v-else>
                        <i class="iconfont icon-icon_minus-circled mr-2"></i>待审核
                      </div>
                    </a>
                  </td>
                  <td class="tab-icon">
                    <i
                      @click="deleteUser(item.sid)"
                      class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                  ></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination
                  @size-change="handleSizeChange"
                  background
                  @current-change="handleCurrentChange"
                  :current-page.sync="pageData.pageNum"
                  :page-size="pageData.pageSize"
                  layout="total, sizes,jumper, prev, pager, next"
                  :total="pageData.total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗开始 -->
    <b-modal
        v-model="userShow"
        id="add-user"
        centered
        size="xl"
        title="账号赎回审核"
        title-class="font-18"
        hide-footer
    >
      <div>
        <div class="mb-3">
          <h4 class="card-title ml-0 "><strong>考生姓名：</strong>{{form.xm}} <strong>身份证件号：</strong>{{form.sfzjh}} </h4>
          <div class="d-flex">
            <div class="per-img-box certificates">
              <div class="per-img-top flexList">身份证扫描件（人像面）</div>
              <div
                  class="per-img-photo certifi flexList justify-content-center"
              >
                <img
                    :src="
                        form.sfzsmjrxm
                          ? getFileUrl(form.sfzsmjrxm)
                          : require('@/assets/images/person/card1.png')
                      "
                    alt="加载失败"
                />
              </div>
            </div>
            <div class="per-img-box certificates">
              <div class="per-img-top flexList">身份证扫描件（国徽面）</div>
              <div
                  class="per-img-photo certifi flexList justify-content-center"
              >
                <img
                    :src="
                        form.sfzsmjghm
                          ? getFileUrl(form.sfzsmjghm)
                          : require('@/assets/images/person/card2.png')
                      "
                    alt="加载失败"
                />
              </div>
            </div>
            <div class="per-img-box certificates">
              <div class="per-img-top flexList">手持证件照：</div>
              <div
                  class="per-img-photo certifi flexList justify-content-center"
              >
                <img
                    :src="
                        form.sczjtx
                          ? getFileUrl(form.sczjtx)
                          : require('@/assets/images/person/card1.png')
                      "
                    alt="加载失败"
                />
              </div>
            </div>
          </div>
        </div>
        
        <div class="flexList check-distri mb-3 w-100" v-if="form.zjxm">
          <div class="flexList">
            <div v-for="(v1, i1) in bounds" :key="i1">
              <div class="p-2">{{i1}}</div>
              <div
                  class="msgContent flexList fs-xs"
              >
                <div class=" flexList" style="flex-wrap: wrap; padding-left: 0">
                  <div class="flexList  w-50" :class="'w-'+v.width" v-for="(v, i) in v1" :key="i" style="height: 30px; line-height: 30px">
                    <div class="msgName label-required">{{v.label}}</div>
                    <div class="msgVal  flexList">
                      <div class="line1 use-circle" v-if="v.type == Boolean">{{form[v.bound]?'是':'否'}}</div>
                      <div class="line1 use-circle" v-if="v.type == 'join'">
                        {{ [form[v.bound[0]], form[v.bound[1]]].join(v.join) }}
                      </div>
                      <div class="line1 use-circle" v-else>{{form[v.bound]}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0">赎回原因：</label>
          <div class="col-sm-11 p-0 flexList">
            <div v-if="form.qtsm">{{form.qtsm}}</div>
            <div v-else>
              <div v-if="form.shyy == 1">身份证件信息被他人恶意注册</div>
              <div v-if="form.shyy == 2">原注册手机号已不再使用</div>
            </div>
          </div>
        </div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0">审核意见：</label>
          <div class="col-sm-11 p-0 flexList">
            <textarea v-model="form.zjshbz" class="form-control w-100"  rows="3" style="overflow-y: auto;"></textarea>
          </div>
        </div>
      </div>
      <div class="text-center mt-3">
        <button
            type="button"
            class="btn btn-info h30 w-10  mr-3"
            @click="handle(1)"
        >
          同意
        </button>
        <button
            type="button"
            class="btn btn-danger h30 w-10  mr-3"
            @click="handle(2)"
        >
          驳回
        </button>
        <button
            type="button"
            class="btn btn-secondary w-10 h30 "
            @click="userShow = false"
        >
          取消
        </button>
      </div>
    </b-modal>
  </Layout>
</template>
