import {getRequest, patchRequest} from "@/libs/axios";


export const getUserAccountBackPage = function (params) {
    return getRequest("/yethan/UserAccountBack/listPage", params)
}
export const getUserAccountBackDetails = function (sid) {
    return getRequest(`/yethan/UserAccountBack/${sid}`)
}
export const handleUserAccountBack = function (sid, params) {
    return patchRequest(`/yethan/UserAccountBack/handle/${sid}`, params)
}
export const deleteUserAccountBack = function (sid) {
    return patchRequest(`/yethan/UserAccountBack/${sid}`)
}